import { apiClient } from "utils/api-client";
import { ICreateMoodleConnection, IMoodleCourse } from "./types";

export const moodleAPIClient = {
  getCourses: async () => {
    const url = `/moodle/courses`;
    let resp = await apiClient.get<IMoodleCourse[]>(url);
    return resp.data;
  },

  getCalendarEventsForCourses: async (courseIds: number[]) => {
    const url = `/moodle/calendar/course?ids=${courseIds.join(",")}`;
    let resp = await apiClient.get<any[]>(url);
    return resp.data;
  },

  createMoodleConnection: async (data: ICreateMoodleConnection) => {
    const url = `/moodle`;
    let resp = await apiClient.post(url, data);
    return resp.data;
  },
};
