import axios from "axios";
import { getCurrentUser, initAuth } from "clients/firebase";
export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_ERP_BACKEND,
});

apiClient.interceptors.request.use(
  (config: any) => {
    const user: any = getCurrentUser();
    if (user && user?.accessToken) {
      // get user session profile from profile service
      let _token = user.accessToken;
      let _config = { ...config }; // shallow copy of the config
      config.headers = _config.headers || {}; // make sure headers is initialized
      config.headers["authorization"] = _token;

      return config;
    } else {
      const firebaseAuth = initAuth();
      firebaseAuth.signOut();
    }
  },
  (error) => {
    Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const firebaseAuth = initAuth();
      firebaseAuth.signOut();
    }
    return Promise.reject(error);
  },
);
