import { useState } from "react";

import InputFieldBasic from "../../../../components/InputField/CustomBasicInput";
import CreateObjectModal from "../../../../components/Modals/CreateObjectModal";
import {
  ConnectMoodleModalInterface,
  ConnectMoodleFormInterface,
} from "./index.d";
import { moodleAPIClient } from "../../../../services/Moodle";
import Loading from "../../../../components/Loader/Spinners";
import { toastifyGeneralTheme } from "../../../../utils/ToastifyGeneral";
import { toast } from "react-toastify";

const MoodleConnectModal = ({ handleClose }: ConnectMoodleModalInterface) => {
  const [moodleObject, setMoodleObject] = useState<ConnectMoodleFormInterface>({
    token: "",
    url: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (event: any) => {
    setMoodleObject({
      ...moodleObject,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    moodleAPIClient
      .createMoodleConnection(moodleObject)
      .then((res) => {
        alert("Successfully Connect moodle");

        window.location.reload();
      })
      .catch((err) =>
        toast.error("Failed to connect Moodle", { ...toastifyGeneralTheme }),
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <CreateObjectModal
      handleClose={handleClose}
      handleSubmit={handleSubmitForm}
      modalName="Connect Moodle"
    >
      <Loading isLoading={isLoading} />
      <div className="flex w-max flex-col gap-4 px-2">
        <div className=" text-left" id="token field">
          <InputFieldBasic
            label="URL"
            placeholder="Enter moodle address..."
            required
            name="url"
            id="url-input"
            value={moodleObject.url}
            onChange={handleInputChange}
            // className="w-full rounded-s-sm bg-slate-200 dark:bg-slate-600"
          />
        </div>
        <div className=" text-left" id="token field">
          <InputFieldBasic
            label="Token"
            placeholder="Enter moodle Connection token..."
            required
            name="token"
            id="token-input"
            value={moodleObject.token}
            onChange={handleInputChange}
            // className="w-full rounded-s-sm bg-slate-200 dark:bg-slate-600"
          />
        </div>
      </div>
    </CreateObjectModal>
  );
};

export default MoodleConnectModal;
