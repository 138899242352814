import { apiClient } from "utils/api-client";
import { IBreadcrumb } from "./types";

export const breadcrumbsAPIClient = {
  getBreadcrumbsForPath: async (pathName: string) => {
    const url = `/breadcrumbs?url=${encodeURIComponent(pathName)}`;
    let resp = await apiClient.get<IBreadcrumb[]>(url);
    return resp.data;
  },
};
